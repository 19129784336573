import App from './App';

import {
    Root,
    MuiThemeProvider,
    ConfigProvider,
    SettingProvider,
    AuthProvider,
    SocketProvider,
    HelmetProvider
} from './providers';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SnackbarProvider from 'components/snackbar';
import { Provider as ReduxProvider } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

// redux
import { store } from './redux/store';
import { AuthConsumer } from 'utils/auth-consumer';

// Initialize Facebook Pixel
ReactPixel.init('450773011166568');
ReactPixel.pageView();

Root.render(
    <ReduxProvider store={store}>
        <SettingProvider>
            <AuthProvider>
                <SocketProvider>
                    <HelmetProvider>
                        <ConfigProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MuiThemeProvider>
                                    <AuthConsumer>
                                        <SnackbarProvider>
                                            <App />
                                        </SnackbarProvider>
                                    </AuthConsumer>
                                </MuiThemeProvider>
                            </LocalizationProvider>
                        </ConfigProvider>
                    </HelmetProvider>
                </SocketProvider>
            </AuthProvider>
        </SettingProvider>
    </ReduxProvider>
);
